import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  paymentHero,
  paymentTrusted,
  paymentWhiteGlove,
  paymentLargeFeatures,
  paymentBusinessTypesCar,
  paymentVideo,
  paymentSolutionsSection,
  paymentsSolutionsSlides,
  paymentTestimonials,
  paymentCTA,
  // paymentFaqs,
} from "../../../data/product-pages/payment-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { heroSuccess, vtSlides } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/PaymentsLF")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "reporting-white-glove.png";

const Payment = () => {
  return (
    <Layout>
      <SEO
        title="POS Payment Processing System & Solutions | SpotOn"
        description="SpotOn payment processing is an all-in-one solution created for businesses & restaurants. Accept payments quickly and securely and increase customer satisfaction."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={paymentHero} circleBg={false} />
      <TrustedNumbers numbersArray={paymentTrusted} />
      <WhiteGlove
        sectionData={paymentWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={paymentLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={paymentBusinessTypesCar} />
      <VideoSection sectionData={paymentVideo} />
      <SuccessStories slides={vtSlides} sectionData={heroSuccess} />
      <PartnerLogos />
      <ProductLines
        sectionData={paymentSolutionsSection}
        productSlides={paymentsSolutionsSlides}
      />
      <TestmonialReviews sectionData={paymentTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={paymentCTA} />
      {/* <Faqs sectionData={paymentFaqs} /> */}
    </Layout>
  );
};

export default Payment;
